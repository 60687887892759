import React from 'react';
import {
  Stack,
  Heading,
  Text,
  theme,
} from '@chakra-ui/core';

import Layout from '../components/display/layout';
import SEO from '../components/seo';
import Card from '../components/display/card';

import { AppText } from '../components/textfiles/contenttext';

const ApplicationsPage = () => (
  <Layout>
    <SEO title={AppText.title} />
    <Heading
      as="h1"
      size="lg"
      m={1}
      style={{ color: theme.colors.orange[500] }}
    >
      {AppText.title}
    </Heading>
    <Stack spacing={5} align="center">
      <Card width={650} style={{ padding: '12px' }}>
        <Heading as="h2" size="md" m={2}>{AppText.commingSoon.name}</Heading>
        <Text>{AppText.commingSoon.desc}</Text>
      </Card>
    </Stack>
    {/* <Stack spacing={5} align="center">
      <Card link="/converter" width={650} style={{ padding: '12px' }}>
        <Heading as="h2" size="md" m={2}>{AppText.converter.name}</Heading>
        <Text>{AppText.converter.desc}</Text>
      </Card>
    </Stack> */}
    {/* <Stack spacing={5} align="center">
      <Card link="/cssgame" width={650} style={{ padding: '12px' }}>
        <Heading as="h2" size="md" m={2}>{AppText.cssgame.name}</Heading>
        <Text>{AppText.cssgame.desc}</Text>
      </Card>
    </Stack>
    <Stack spacing={5} align="center">
      <Card link="/nains" width={650} style={{ padding: '12px' }}>
        <Heading as="h2" size="md" m={2}>{AppText.nains.name}</Heading>
        <Text>{AppText.nains.desc}</Text>
      </Card>
    </Stack> */}
  </Layout>
);

export default ApplicationsPage;
